import React, { useState, useEffect, useLayoutEffect } from 'react';
import FooterTwo from '../common/elements/footer/FooterTwo';
import HeadTitle from '../common/elements/head/HeadTitle';
import HeaderFive from '../common/elements/header/HeaderFive';
import PostSectionThirteen from '../common/components/post/PostSectionThirteen';
import PostSectionFour from '../common/components/post/PostSectionFour';
import PostSectionSeven from '../common/components/post/PostSectionSeven';
import WidgetPostList from "../common/components/sidebar/WidgetPostList";
import TagListSlide from "../common/components/tag/TagListSlide";
import PostLayoutTwo from "../common/components/post/layout/PostLayoutTwo";
import { client } from '../../lib/apollo';
import { gql } from "@apollo/client";
import { getSelectorsByUserAgent } from "react-device-detect"

const HomeDefault = ({ posts, hotTag, categories, highlightPosts, popularPosts, seo, isMobile }) => {

  // {
  //   "props": {
  //       "posts": [
  //           {
  //               "__typename": "Post",
  //               "postId": 2009918,
  //               "date": "2024-11-28T11:23:10",
  //               "slug": "thuong-hieu-tui-xac-ther-gab-mung-sinh-nhat-2-tuoi",
  //               "title": "THƯƠNG HIỆU TÚI XÁCH THER GAB MỪNG SINH NHẬT 2 TUỔI VỚI CHUỖI SỰ KIỆN KHUÊ CÁC MẮT MÀU MƠ",
  //               "categories": {
  //                   "__typename": "PostToCategoryConnection",
  //                   "nodes": [
  //                       {
  //                           "__typename": "Category",
  //                           "name": "Lifestyle",
  //                           "slug": "life-style"
  //                       },
  //                       {
  //                           "__typename": "Category",
  //                           "name": "Thời trang",
  //                           "slug": "thoi-trang"
  //                       }
  //                   ]
  //               },
  //               "featuredImage": {
  //                   "__typename": "NodeWithFeaturedImageToMediaItemConnectionEdge",
  //                   "node": {
  //                       "__typename": "MediaItem",
  //                       "altText": "",
  //                       "sourceUrl": "https://api.pose.vn/wp-content/uploads/2024/11/THER-GAB.jpg"
  //                   }
  //               },
  //               "seo": {
  //                   "__typename": "PostTypeSEO",
  //                   "opengraphDescription": "Chuỗi sự kiện của Ther Gab ở TP. Hồ Chí Minh và Hà Nội để kỷ niệm 2 năm thành lập thương hiệu với tâm điểm là private fashion show và pop-up Khuê Các Mắt Màu Mơ"
  //               }
  //           },
  //           {
  //               "__typename": "Post",
  //               "postId": 2009912,
  //               "date": "2024-10-10T09:53:18",
  //               "slug": "hoa-hau-khanh-van-khoe-anh-cuoi-gay-sot-cong-dong-mang",
  //               "title": "HOA HẬU KHÁNH VÂN KHOE ẢNH CƯỚI GÂY SỐT CỘNG ĐỒNG MẠNG",
  //               "categories": {
  //                   "__typename": "PostToCategoryConnection",
  //                   "nodes": [
  //                       {
  //                           "__typename": "Category",
  //                           "name": "Hoa hậu",
  //                           "slug": "hoa-hau"
  //                       }
  //                   ]
  //               },
  //               "featuredImage": {
  //                   "__typename": "NodeWithFeaturedImageToMediaItemConnectionEdge",
  //                   "node": {
  //                       "__typename": "MediaItem",
  //                       "altText": "",
  //                       "sourceUrl": "https://api.pose.vn/wp-content/uploads/2024/10/462617119_1107948360707874_5951052644137549756_n.jpg"
  //                   }
  //               },
  //               "seo": {
  //                   "__typename": "PostTypeSEO",
  //                   "opengraphDescription": "Khoảnh khắc Khánh Vân chụp cùng dàn phù dâu khiến dân mạng thích thú. Nhiều người đồn đoán về danh tính những người đẹp góp mặt trong ảnh."
  //               }
  //           },
  //           {
  //               "__typename": "Post",
  //               "postId": 2009907,
  //               "date": "2024-10-09T11:43:44",
  //               "slug": "do-thi-ha-chinh-thuc-len-tieng-ve-tin-don-co-thai",
  //               "title": "ĐỖ THỊ HÀ CHÍNH THỨC LÊN TIẾNG VỀ TIN ĐỒN CÓ THAI",
  //               "categories": {
  //                   "__typename": "PostToCategoryConnection",
  //                   "nodes": [
  //                       {
  //                           "__typename": "Category",
  //                           "name": "Hoa hậu",
  //                           "slug": "hoa-hau"
  //                       }
  //                   ]
  //               },
  //               "featuredImage": {
  //                   "__typename": "NodeWithFeaturedImageToMediaItemConnectionEdge",
  //                   "node": {
  //                       "__typename": "MediaItem",
  //                       "altText": "",
  //                       "sourceUrl": "https://api.pose.vn/wp-content/uploads/2024/10/hoa-hau-do-thi-ha-6336-9523-e1728448908471.jpeg"
  //                   }
  //               },
  //               "seo": {
  //                   "__typename": "PostTypeSEO",
  //                   "opengraphDescription": "Tin đồn Hoa hậu Đỗ Thị Hà mang thai xuất hiện từ một số hành động của nàng hậu tại họp báo trao sash cho Hoa hậu Thanh Thủy."
  //               }
  //           },
  //           {
  //               "__typename": "Post",
  //               "postId": 2009901,
  //               "date": "2024-10-09T09:46:19",
  //               "slug": "hoa-hau-thanh-thuy-va-a-hau-bui-khanh-linh-nhan-sash-ra-quoc-te",
  //               "title": "HOA HẬU THANH THỦY VÀ Á HẬU BÙI KHÁNH LINH NHẬN SASH RA QUỐC TẾ",
  //               "categories": {
  //                   "__typename": "PostToCategoryConnection",
  //                   "nodes": [
  //                       {
  //                           "__typename": "Category",
  //                           "name": "Hoa hậu",
  //                           "slug": "hoa-hau"
  //                       }
  //                   ]
  //               },
  //               "featuredImage": {
  //                   "__typename": "NodeWithFeaturedImageToMediaItemConnectionEdge",
  //                   "node": {
  //                       "__typename": "MediaItem",
  //                       "altText": "",
  //                       "sourceUrl": "https://api.pose.vn/wp-content/uploads/2024/10/image-16.jpeg"
  //                   }
  //               },
  //               "seo": {
  //                   "__typename": "PostTypeSEO",
  //                   "opengraphDescription": "Chiều ngày 8/10, nhan sắc Việt Nam có thêm 2 đại diện tham gia các đấu trường quốc tế. Đó là Huỳnh Thị Thanh Thuỷ dự thi Miss International – Hoa hậu Quốc tế 2024 và Bùi Khánh Linh dự thi Miss Intercontinental – Hoa hậu Liên lục địa 2024. Hai người đẹp nhận sash Việt […]"
  //               }
  //           },
  //           {
  //               "__typename": "Post",
  //               "postId": 2009891,
  //               "date": "2024-10-08T14:23:41",
  //               "slug": "su-kien-the-gioi-hai-san-tiep-don-btc-va-thi-sinh-miss-cosmo-2024",
  //               "title": "SỰ KIỆN THẾ GIỚI HẢI SẢN TIẾP ĐÓN BTC VÀ THÍ SINH MISS COSMO 2024",
  //               "categories": {
  //                   "__typename": "PostToCategoryConnection",
  //                   "nodes": [
  //                       {
  //                           "__typename": "Category",
  //                           "name": "Hoa hậu",
  //                           "slug": "hoa-hau"
  //                       }
  //                   ]
  //               },
  //               "featuredImage": {
  //                   "__typename": "NodeWithFeaturedImageToMediaItemConnectionEdge",
  //                   "node": {
  //                       "__typename": "MediaItem",
  //                       "altText": "",
  //                       "sourceUrl": "https://api.pose.vn/wp-content/uploads/2024/10/z5908553736031_a53d84791aea03e96d31709ab4d4b0ea.jpg"
  //                   }
  //               },
  //               "seo": {
  //                   "__typename": "PostTypeSEO",
  //                   "opengraphDescription": "Hà Nội, ngày 14 tháng 9 năm 2024 – Nhà hàng Siêu thị Thế Giới Hải Sản tại cơ sở Tháp C Golden Palace, 99 Mễ Trì, Hà Nội, hân hạnh tổ chức sự kiện đặc biệt chào đón Ban Tổ Chức cùng gần 100 thí sinh của cuộc thi sắc đẹp quốc tế MISS COSMO […]"
  //               }
  //           },
  //           {
  //               "__typename": "Post",
  //               "postId": 2009886,
  //               "date": "2024-10-08T14:00:43",
  //               "slug": "do-ha-trang-duoc-du-doan-vao-top-5-the-miss-globe",
  //               "title": "ĐỖ HÀ TRANG ĐƯỢC DỰ ĐOÁN VÀO TOP 5 THE MISS GLOBE",
  //               "categories": {
  //                   "__typename": "PostToCategoryConnection",
  //                   "nodes": [
  //                       {
  //                           "__typename": "Category",
  //                           "name": "Hoa hậu",
  //                           "slug": "hoa-hau"
  //                       }
  //                   ]
  //               },
  //               "featuredImage": {
  //                   "__typename": "NodeWithFeaturedImageToMediaItemConnectionEdge",
  //                   "node": {
  //                       "__typename": "MediaItem",
  //                       "altText": "",
  //                       "sourceUrl": "https://api.pose.vn/wp-content/uploads/2024/10/image-15.jpeg"
  //                   }
  //               },
  //               "seo": {
  //                   "__typename": "PostTypeSEO",
  //                   "opengraphDescription": "Người đẹp Việt Nam - Đỗ Hà Trang rạng rỡ trong trang phục dân tộc tại Hoa hậu Hoàn cầu 2024 (The Miss Globe 2024)."
  //               }
  //           },
  //           {
  //               "__typename": "Post",
  //               "postId": 2009882,
  //               "date": "2024-10-08T10:24:50",
  //               "slug": "nhan-sac-dan-thi-sinh-hoa-hau-quoc-gia-viet-nam-ngay-so-khao",
  //               "title": "NHAN SẮC DÀN THÍ SINH HOA HẬU QUỐC GIA VIỆT NAM NGÀY SƠ KHẢO",
  //               "categories": {
  //                   "__typename": "PostToCategoryConnection",
  //                   "nodes": [
  //                       {
  //                           "__typename": "Category",
  //                           "name": "Hoa hậu",
  //                           "slug": "hoa-hau"
  //                       }
  //                   ]
  //               },
  //               "featuredImage": {
  //                   "__typename": "NodeWithFeaturedImageToMediaItemConnectionEdge",
  //                   "node": {
  //                       "__typename": "MediaItem",
  //                       "altText": "",
  //                       "sourceUrl": "https://api.pose.vn/wp-content/uploads/2024/10/image-14-e1728357876410.jpeg"
  //                   }
  //               },
  //               "seo": {
  //                   "__typename": "PostTypeSEO",
  //                   "opengraphDescription": "Thảo Trang, Gia Hân, Cẩm Ly… là những thí sinh nổi bật ở sơ khảo Hoa hậu Quốc gia Việt Nam 2024, hôm 6/10. Nguyễn Thị Thảo Trang sinh năm 2001, quê Điện Biên, có gương mặt khả ái. ‘Tôi sẽ nỗ lực đem màu sắc tươi mới, lan tỏa năng lượng tích cực tới […]"
  //               }
  //           },
  //           {
  //               "__typename": "Post",
  //               "postId": 2009876,
  //               "date": "2024-10-08T10:21:14",
  //               "slug": "nhan-sac-trong-treo-cua-hoa-hau-y-nhin-gay-thuong-nho",
  //               "title": "NHAN SẮC TRONG TRẺO CỦA HOA HẬU Ý NHIN GÂY THƯƠNG NHỚ",
  //               "categories": {
  //                   "__typename": "PostToCategoryConnection",
  //                   "nodes": [
  //                       {
  //                           "__typename": "Category",
  //                           "name": "Hoa hậu",
  //                           "slug": "hoa-hau"
  //                       }
  //                   ]
  //               },
  //               "featuredImage": {
  //                   "__typename": "NodeWithFeaturedImageToMediaItemConnectionEdge",
  //                   "node": {
  //                       "__typename": "MediaItem",
  //                       "altText": "",
  //                       "sourceUrl": "https://api.pose.vn/wp-content/uploads/2024/10/image-13.jpeg"
  //                   }
  //               },
  //               "seo": {
  //                   "__typename": "PostTypeSEO",
  //                   "opengraphDescription": "Hoa hậu Ý Nhi hút hồn với bộ ảnh xinh xắn, quyến rũ sau khi vừa đặt chân trở lại Úc."
  //               }
  //           },
  //           {
  //               "__typename": "Post",
  //               "postId": 2009867,
  //               "date": "2024-10-07T16:18:14",
  //               "slug": "top-2-miss-cosmo-2024-do-sac-cuc-slay-trong-bo-anh-profile-hau-dang-quang",
  //               "title": "TOP 2 MISS COSMO 2024 ĐỌ SẮC CỰC SLAY TRONG BỘ ẢNH PROFILE HẬU ĐĂNG QUANG",
  //               "categories": {
  //                   "__typename": "PostToCategoryConnection",
  //                   "nodes": [
  //                       {
  //                           "__typename": "Category",
  //                           "name": "Hoa hậu",
  //                           "slug": "hoa-hau"
  //                       }
  //                   ]
  //               },
  //               "featuredImage": {
  //                   "__typename": "NodeWithFeaturedImageToMediaItemConnectionEdge",
  //                   "node": {
  //                       "__typename": "MediaItem",
  //                       "altText": "",
  //                       "sourceUrl": "https://api.pose.vn/wp-content/uploads/2024/10/z5905462995733_4a4126a4c54665263e38313e8db0403e.jpg"
  //                   }
  //               },
  //               "seo": {
  //                   "__typename": "PostTypeSEO",
  //                   "opengraphDescription": "Vượt qua các đại diện trên toàn thế giới, Miss Cosmo Indonesia - Ketut Permata Juliastrid và Miss Cosmo Thailand - Karnruethai Tassabut đã xuất sắc trở thành Hoa, Á hậu đầu tiên trong lịch sử Miss Cosmo một cách vô cùng thuyết phục. Ngay sau Đêm Chung kết diễn ra vào tối 05/10/2024 tại Công viên bờ Sông Sài Gòn (TP.HCM), Hoa hậu Ketut Permata Juliastrid và Á hậu Karnruethai Tassabut đã nhanh chóng thực hiện bộ ảnh profile để ra mắt công chúng."
  //               }
  //           },
  //           {
  //               "__typename": "Post",
  //               "postId": 2009864,
  //               "date": "2024-10-07T11:10:09",
  //               "slug": "nguoi-dep-trang-nhung-dai-dien-viet-nam-thi-miss-equality-world-2024",
  //               "title": "NGƯỜI ĐẸP TRANG NHUNG ĐẠI DIỆN VIỆT NAM THI MISS EQUALITY WORLD 2024",
  //               "categories": {
  //                   "__typename": "PostToCategoryConnection",
  //                   "nodes": [
  //                       {
  //                           "__typename": "Category",
  //                           "name": "Hoa hậu",
  //                           "slug": "hoa-hau"
  //                       }
  //                   ]
  //               },
  //               "featuredImage": {
  //                   "__typename": "NodeWithFeaturedImageToMediaItemConnectionEdge",
  //                   "node": {
  //                       "__typename": "MediaItem",
  //                       "altText": "",
  //                       "sourceUrl": "https://api.pose.vn/wp-content/uploads/2024/10/461953904_8373582446083154_5958736274564655402_n-1.jpg"
  //                   }
  //               },
  //               "seo": {
  //                   "__typename": "PostTypeSEO",
  //                   "opengraphDescription": "Sắp tới, người đẹp Nguyễn Trang Nhung (nghệ danh Bolo Nguyễn) sẽ chính thức lên đường dự thi Miss Equality World 2024."
  //               }
  //           },
  //           {
  //               "__typename": "Post",
  //               "postId": 2009860,
  //               "date": "2024-10-07T10:31:52",
  //               "slug": "co-gai-indonesia-dang-quang-miss-cosmo-2024",
  //               "title": "CÔ GÁI INDONESIA ĐĂNG QUANG MISS COSMO 2024",
  //               "categories": {
  //                   "__typename": "PostToCategoryConnection",
  //                   "nodes": [
  //                       {
  //                           "__typename": "Category",
  //                           "name": "Hoa hậu",
  //                           "slug": "hoa-hau"
  //                       }
  //                   ]
  //               },
  //               "featuredImage": {
  //                   "__typename": "NodeWithFeaturedImageToMediaItemConnectionEdge",
  //                   "node": {
  //                       "__typename": "MediaItem",
  //                       "altText": "",
  //                       "sourceUrl": "https://api.pose.vn/wp-content/uploads/2024/10/image.png"
  //                   }
  //               },
  //               "seo": {
  //                   "__typename": "PostTypeSEO",
  //                   "opengraphDescription": "Tối 5/10, chung kết Miss Cosmo 2024 diễn ra tại TPHCM. Danh hiệu cao nhất thuộc về đại diện của Indonesia. Bùi Xuân Hạnh của Việt Nam lọt top 5 và giành giải Trang phục dạ hội đẹp nhất."
  //               }
  //           }
  //       ],
  //       "endCursor": "YXJyYXljb25uZWN0aW9uOjIwMDk4NjA=",
  //       "hasNextPage": true
  //   }
  // }

  // sort thuong-hieu-tui-xac-ther-gab-mung-sinh-nhat-2-tuoi to top 8 of posts

  const [newPosts, setNewPosts] = useState(posts); // Use props.posts if `posts` is inside `props`

  // Function to move the specific post to top 8
  const movePostToTop8 = (posts) => {

    if (!Array.isArray(posts.props.posts)) {
      return {
        props: {
          posts: posts.props.posts,
        }
      }
    }

    // Find the post with the specific slug
    const targetPostIndex = posts.props.posts.findIndex(post => post.slug === "thuong-hieu-tui-xac-ther-gab-mung-sinh-nhat-2-tuoi");
    const targetPost = posts.props.posts[targetPostIndex];

    // If the post exists, move it to the top 8
    if (targetPostIndex !== -1) {
      // Remove the target post from the original array
      const postsWithoutTarget = posts.props.posts.filter(post => post.slug !== "thuong-hieu-tui-xac-ther-gab-mung-sinh-nhat-2-tuoi");

      // Create the new posts array with the target post at the top and ensuring only 8 posts in total
      const newPostsArray = [...postsWithoutTarget.slice(0, 7), targetPost, ...postsWithoutTarget.slice(7)];

      return {
        props: {
          posts: newPostsArray,
          endCursor: posts.props.endCursor,
          hasNextPage: posts.props.hasNextPage
        }
      }
    }
    return {
      props: {
        posts: posts.props.posts,
        endCursor: posts.props.endCursor,
        hasNextPage: posts.props.hasNextPage
      }
    }
  };

  // Move the post and update the state

  useEffect(() => {
    const sortedPosts = movePostToTop8(posts); // Use props.posts here as well
    setNewPosts(sortedPosts);
  }, []); // Use props.posts here as well


  const [pageNumber, setPageNumber] = useState(0);

  const [loading, setLoading] = useState(false);

  const [scrollDir, setScrollDir] = useState(false);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? false : true);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  const postsPerPage = 5;

  useEffect(() => {
    if (pageNumber === 0) return;
    setLoading(true);

    const getNewPosts = async () => {
      const response = await client.query({
        query: gql`
        query GetNewPosts {
          posts(where: {orderby: {field: DATE, order: DESC}, taxQuery: {taxArray: [{taxonomy: CATEGORY, field: SLUG, terms: ["noi-bat"], includeChildren: false, operator: NOT_IN}, {taxonomy: CATEGORY, field: SLUG, includeChildren: false, operator: EXISTS}]}}, first: ${postsPerPage}, after: "${newPosts.props.endCursor}") {
            nodes {
              postId
              date
              slug
              title
              categories {
                nodes {
                  name
                  slug
                }
              }
              featuredImage {
                node {
                  altText
                  sourceUrl
                }
              }
              seo {
                opengraphDescription
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      `
      });
      const nextPosts = response?.data?.posts?.nodes;
      setNewPosts({
        props: {
          posts: [...newPosts.props.posts, ...nextPosts],
          endCursor: response?.data?.posts?.pageInfo?.endCursor,
          hasNextPage: response?.data?.posts?.pageInfo?.hasNextPage
        }
      })
      setLoading(false);
    }
    getNewPosts()
  }, [pageNumber]);

  const checkLGLayout = () => {
    if (window.innerWidth < 1200) {
      return true;
    }
    return false;
  }

  const handleScroll = () => {
    const sidebar = document.getElementById('sidebar');
    const lastWidget = sidebar.lastElementChild;
    const lastWidgetAbove = lastWidget.previousElementSibling?.getBoundingClientRect().bottom || sidebar.getBoundingClientRect().top;
    if (lastWidgetAbove < (checkLGLayout() ? 120 : 90) && !scrollDir) {
      lastWidget.classList.add('fixed');
    }
    if (lastWidgetAbove > (checkLGLayout() ? 120 : 90) && scrollDir) {
      lastWidget.classList.remove('fixed');
    }
    if (lastWidget.getBoundingClientRect().bottom > sidebar.getBoundingClientRect().bottom && !scrollDir) {
      lastWidget.classList.add('absolute');
    }
    if (lastWidget.getBoundingClientRect().top > (checkLGLayout() ? 120 : 90) && !scrollDir) {
      lastWidget.classList.remove('absolute');
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [scrollDir]);

  useEffect(() => {
    if (document.getElementById('pc-masthead') && document.getElementById('pc-masthead').offsetHeight === 0 && process.env.NEXT_PUBLIC_ADS_HOMEPAGE_MASTHEAD) {
      document.getElementById('pc-masthead').innerHTML = `
      <div class='d-none d-lg-block mt--80 mt_lg--120 mt_md--120 mt_sm--120 mt_mobile--120'>
        <iframe style="position: relative; left: 50%;transform: translateX(-50%)" src=${process.env.NEXT_PUBLIC_ADS_HOMEPAGE_MASTHEAD} width="970" height="250" marginWidth="0" marginHeight="0" frameBorder="0" scrolling="no"></iframe>
      </div>
      `
    }
    const sidebar = document.getElementById('sidebar');
    const lastWidget = sidebar.lastElementChild;
    lastWidget.classList.remove('fixed');
  }, []);

  // useLayoutEffect(() => {
  //   handleScroll();
  // }, []);

  useEffect(() => {
    handleScroll();
    var script = document.createElement('script');
    script.innerHTML = `
      var category = 'home';
    `;
    script.id = "state";
    document.body.appendChild(script);
  }, []);

  // useEffect(() => {
  // if (!mobileCheck()) {
  //   var script1 = document.createElement('script');
  //   script1.innerHTML = `
  //       var bsWrapAds = bsWrapAds || []; bsWrapAds.push(["deacf352f872af99d67ef8172c59cfd2"]);
  //     `;
  //   script1.id = "bs-wrap-popup";
  //   document.getElementById('content').appendChild(script1);
  //   var script = document.createElement('script');
  //   script.src = "//static.blueseed.tv/ajs/bs.rigel.instercroller.js";
  //   script.id = "bs-deacf352f872af99d67ef8172c59cfd2";
  //   document.getElementById('content').appendChild(script);

  //   var script2 = document.createElement('script');
  //   script2.innerHTML = `
  //     var bsWrapAds = bsWrapAds || []; bsWrapAds.push(["bfd813b99e638b55f29fd9e82d78995a"]);
  //     `;
  //   script2.id = "bs-wrap-masthead";
  //   document.getElementById('pc-masthead').appendChild(script2);
  //   var script3 = document.createElement('script');
  //   script3.src = "//static.blueseed.tv/ajs/bs.rigel.instercroller.js";
  //   script3.id = "bs-bfd813b99e638b55f29fd9e82d78995a";
  //   document.getElementById('pc-masthead').appendChild(script3);

  // } else {
  //   var script2 = document.createElement('script');
  //   script2.innerHTML = `
  //     var bsWrapAds = bsWrapAds || []; bsWrapAds.push(["f6fcc084b2242b15db982eda47b93018"]);
  //     `;
  //   script2.id = "bs-wrap-masthead";
  //   document.getElementById('mb-masthead').appendChild(script2);
  //   var script3 = document.createElement('script');
  //   script3.src = "//static.blueseed.tv/ajs/bs.rigel.instercroller.js";
  //   script3.id = "bs-f6fcc084b2242b15db982eda47b93018";
  //   document.getElementById('mb-masthead').appendChild(script3);
  // }
  // }, []);

  return (
    <>
      <div className="container content d-none d-sm-block" id='content'>
        {
          !isMobile &&
          <div id='pc-masthead' className='mt--80 mt_lg--120 mt_md--120 mt_sm--120 mt_mobile--120'>
          </div>
        }
        <HeadTitle seo={seo} />
        <HeaderFive />
        <div className={`page mt_md--120 mt_sm--120 mt_mobile--120 ${process.env.NEXT_PUBLIC_ADS_HOMEPAGE_MASTHEAD ? "mt--10 mt_lg--20" : "mt--80 mt_lg--120"}`}>
          <div className="page-content">
            <div className="row">
              <div className="col">
                <PostSectionThirteen postData={newPosts} pClass="" />
                <TagListSlide tagData={hotTag} />
                <PostLayoutTwo dataPost={newPosts} />
                {
                  newPosts.props.hasNextPage &&
                  <div className="post-button">
                    <div
                      className="axil-button button-rounded color-foundation-primary-color-normal mt--24"
                      aria-label="Xem thêm bài viết"
                      onClick={() => setPageNumber(pageNumber + 1)}
                    >
                      Xem thêm bài viết
                      {
                        loading && <div className="spinner-border text-light" style={{ width: 15, height: 15 }} role="status">
                        </div>
                      }
                    </div>
                  </div>
                }
                <PostSectionFour dataPost={highlightPosts} page="home" />
                <PostSectionSeven categoryData={categories} currentCategory='hoa-hau' />
                <PostSectionSeven categoryData={categories} currentCategory='life-style' />
                <PostSectionSeven categoryData={categories} currentCategory='giai-tri' />
                {/* <PostSectionSeven categoryData={categories} currentCategory='short-clip' /> */}
              </div>
            </div>
          </div>
          <div className="sidebar" id="sidebar">
            <WidgetPostList postData={popularPosts} lastElement={process.env.NEXT_PUBLIC_ADS_HOMEPAGE_300x600?.trim() === ''} />
            {
              process.env.NEXT_PUBLIC_ADS_HOMEPAGE_300x600?.split(',').length > 1 && process.env.NEXT_PUBLIC_ADS_HOMEPAGE_300x600?.split(',').slice(1).map((item, index) => {
                if (item) {
                  return (
                    <div className='mb--30' key={index}>
                      <iframe src={item} width="300" height="600" marginWidth="0" marginHeight="0" frameBorder="0" scrolling="no"></iframe>
                    </div>
                  )
                }
              })
            }
          </div>
        </div>
      </div>
      <div className="content d-block d-sm-none">
        {
          isMobile && <div id='mb-masthead' className='mt--80 mt_lg--115 mt_md--115 mt_sm--115 mt_mobile--115'></div>
        }
        <HeadTitle seo={seo} />
        <div className="container mt--15">
          <HeaderFive />
        </div>
        <div className="page">
          <div className="page-content">
            <div className="row">
              <div className="col">
                <div className="container">
                  <PostSectionThirteen postData={newPosts} pClass="" />
                </div>
                <TagListSlide tagData={hotTag} />
                <div className="container">
                  <PostLayoutTwo dataPost={newPosts} isMobile={isMobile} />
                  {
                    newPosts.props.hasNextPage &&
                    <div className="post-button">
                      <div
                        className="axil-button button-rounded color-foundation-primary-color-normal mt--24"
                        aria-label="Xem thêm bài viết"
                        onClick={() => setPageNumber(pageNumber + 1)}
                      >
                        Xem thêm bài viết
                        {
                          loading && <div className="spinner-border text-light" style={{ width: 15, height: 15 }} role="status">
                          </div>
                        }
                      </div>
                    </div>
                  }
                  <PostSectionFour dataPost={highlightPosts} page="home" />
                  <PostSectionSeven categoryData={categories} currentCategory='hoa-hau' />
                  <PostSectionSeven categoryData={categories} currentCategory='life-style' />
                  <PostSectionSeven categoryData={categories} currentCategory='giai-tri' />
                  {/* <PostSectionSeven categoryData={categories} currentCategory='short-clip' /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {
          isMobile && <div id="mb-inflow"></div>
        } */}
      </div>
      <FooterTwo bgColor="bg-color-grey" />
      <div id="ads-mobile" className='d-none d-sm-block'></div>
    </>
  );
}

export default HomeDefault;


// export async function getStaticProps() {
export async function getServerSideProps({ req, res }) {
  const userAgent = req.headers['user-agent'] || '';
  const { isMobile } = getSelectorsByUserAgent(userAgent);

  const posts = await getNewPosts()

  const hotTag = await getHotTag()

  const categories = await getCategory()

  const highlightPosts = await getHighlightPosts()

  const popularPosts = await getPopularPosts()

  const seo = await getSeo()

  return {
    props: {
      posts,
      hotTag,
      categories,
      highlightPosts,
      popularPosts,
      seo,
      isMobile
    }
  }
}

export async function getNewPosts() {
  const GET_NEW_POSTS = gql`
    query GetNewPosts {
      posts(where: {orderby: {field: DATE, order: DESC}, taxQuery: {taxArray: [{taxonomy: CATEGORY, field: SLUG, terms: ["noi-bat"], includeChildren: false, operator: NOT_IN}, { taxonomy: CATEGORY, field: SLUG, terms: ["demo"], includeChildren: false, operator: NOT_IN }, {taxonomy: CATEGORY, field: SLUG, includeChildren: false, operator: EXISTS}]}}, first: 11) {
        nodes {
          postId
          date
          slug
          title
          categories {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          seo {
            opengraphDescription
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `;
  const response = await client.query({
    query: GET_NEW_POSTS
  });
  const posts = response?.data?.posts?.nodes;
  const endCursor = response?.data?.posts?.pageInfo?.endCursor;
  const hasNextPage = response?.data?.posts?.pageInfo?.hasNextPage;
  return {
    props: {
      posts,
      endCursor,
      hasNextPage
    }
  }
}

export async function getHotTag() {
  const GET_HOT_TAG = gql`
    query GetHotTag {
      popularTags(first: 10) {
        nodes {
          count
          imageUrl
          name
          slug
          tagViewsCount
          databaseId
          posts(first: 1) {
            nodes {
              categories {
                nodes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  `;
  const response = await client.query({
    query: GET_HOT_TAG
  });
  const tags = response?.data?.popularTags?.nodes;

  return {
    props: {
      tags
    }
  }
}

export async function getCategory() {
  const GET_CATEGORY = gql`
    query GetCategory {
      categories {
        nodes {
          categoryId
          name
          slug
          children {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  `
  const response = await client.query({
    query: GET_CATEGORY
  })
  const categories = response?.data?.categories?.nodes
  return {
    props: {
      categories
    }
  }
}

export async function getHighlightPosts() {
  const GET_HIGHLIGHT_POSTS = gql`
    query GetHighlightPosts {
      posts(
        where: {orderby: {field: DATE, order: DESC}, categoryName: "Nổi bật"}
        first: 4
      ) {
        nodes {
          postId
          date
          slug
          title
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          seo {
            opengraphDescription
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  `;
  const response = await client.query({
    query: GET_HIGHLIGHT_POSTS
  });
  const posts = response?.data?.posts?.nodes;
  return posts
}

export async function getPopularPosts() {
  const GET_POPULAR_POSTS = gql`
    query GetPopularPosts {
      popularPosts(first: 7) {
        nodes {
          title
          postViewsCount
          slug
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  `
  const response = await client.query({
    query: GET_POPULAR_POSTS
  })
  const posts = response?.data?.popularPosts?.nodes
  return posts
}

export async function getSeo() {
  const GET_SEO = gql`
    query GetSEO {
      page(id: "trang-chu", idType: URI) {
        seo {
          fullHead
          title
          opengraphDescription
        }
      }
    }
  `
  const response = await client.query({
    query: GET_SEO
  })
  const seo = response?.data?.page?.seo
  return seo
}


